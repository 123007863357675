import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import '../videoroom.scss';
import * as audioActions from "../../redux/actions/audioActions";
import * as videoroomActions from "../../redux/actions/videoroomActions";
import { setCurrentVideoQuality, setCurrentVideoDevice, setIsUserFacingCamera, setCameraStatus } from '../../redux/actions/videoActions';
import { getUserHSLcolor } from '../utils/Utils';
import { VIDEO_OPTIONS as videoOptions, COMPANY, QUALITY } from "../../constants/Enums";
import UpwardArrow from '../images/upward-arrow.svg';
import DownwardArrow from '../images/downward-arrow.svg';
import UpGrey from '../images/up-grey.svg';
import DownGrey from '../images/down-grey.svg';
import Loader from '../components/Loader';
import ToggleCamera from '../images/toggle-camera.svg';
import Tooltip from "./Tooltip/Tooltip";
import { useTranslation } from 'react-i18next';
import { updateIsPopupOpened } from '../../redux/actions/modals';

import {
    MicGreen,
    MicRed,
    VideoGreen,
    VideoRed,
    RecordingOn,
    RecordingOff,
    Separator,
    MicGrey,
    CameraGrey
} from "../images";

export default function GoLivePreview({
    webrtc,
    loading
}) {
    const { closeAllPopups } = useSelector(state => state.modals);
    const { mic, audioDevices, currAudioDevice, audioPermissionDenied } = useSelector(state => state.audio)
    const { camera, currVideoQuality, currVideoDevice,
        isUserFacingCam, videoDevices, videoPermissionDenied } = useSelector(state => state.video)
    const { device, userId, classInfo, companyId, recording } = useSelector(state => state.videoroom)
    const dispatch = useDispatch()
    const [showQualityOption, setShowQualityOption] = useState(false);
    const [showVideoDevice, setShowVideoDevice] = useState(false);
    const [showAudioDevice, setShowAudioDevice] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        if (closeAllPopups) {
            setShowAudioDevice(false)
            setShowVideoDevice(false)
            setShowQualityOption(false)
        }
    }, [closeAllPopups])

    const onClickGoLive = () => {
        dispatch(videoroomActions.updateSetting({
            setting: "gotoVideoroom",
            value: true
        }))
    }

    const setVideoQuality = async (option) => {
        if (!videoPermissionDenied) {
            dispatch(setCurrentVideoQuality(option))
            let pstream = document.getElementById('preview_video').srcObject;
            webrtc.setPreviewVideo(pstream)
            webrtc.updatePreviewVideoQuality(option)
        }
    }

    useEffect(() => {
        if (!videoPermissionDenied) {
            dispatch(
                setCurrentVideoQuality({
                    ...videoOptions[classInfo?.settings?.video_quality],
                })
            );
        }
    }, [classInfo?.settings?.video_quality, videoOptions]);

    const handlePreviewVideo = async (cameraState) => {
        dispatch(setCameraStatus(!cameraState))
        if (!cameraState) {
            try {
                const { videoStream } = await webrtc.getVideoStream()
                webrtc.setPreviewVideo(videoStream)
            }
            catch { }
        }
        else {
            webrtc.stopPreviewTracks()
        }
    }

    const setDevicePreviewVideo = async (device) => {
        setShowVideoDevice(false)
        dispatch(videoroomActions.setVideo(true))
        try {
            dispatch(setCurrentVideoDevice(device))
            const { videoStream } = await webrtc.getVideoStream()
            webrtc.setPreviewVideo(videoStream)
        }
        catch { }
    }

    const setDevicePreviewAudio = (device) => {
        setShowAudioDevice(false)
        dispatch(audioActions.setCurrentAudioDevice(device))
    }

    const toggleCamera = async () => {
        dispatch(setIsUserFacingCamera(!isUserFacingCam))
        try {
            const { videoStream } = await webrtc.getVideoStream()
            webrtc.setPreviewVideo(videoStream)
        }
        catch { }
    }

    const isLaptop = device && device.laptop;
    const isMobile = device && device.mobile;

    return (
        <>
            <div className='fullscreen'>
                <Loader show={loading} />
                {isLaptop && <div className='go-live-header'></div>}

                <div className='go-live-container'>
                    <div className='go-live-sub'>
                        {isMobile ?
                            <>
                                <div className='go-live-classname center'>
                                    {classInfo.name}
                                </div>

                                <div className='go-live-recording center'>
                                    {recording ? t("goLiveClassRecordingOnText") : t("goLiveClassRecordingOffText")}
                                </div>
                            </>
                            : null
                        }
                        <div className='go-live-video'>
                            {!isLaptop ?
                                <img alt='switch-camera' className='switch-camera' src={ToggleCamera}
                                    style={{
                                        opacity: camera ? '1' : '0.4',
                                    }}
                                    onClick={() => {
                                        if (camera)
                                            toggleCamera();
                                    }}
                                />
                                : null}

                            <video
                                id="preview_video"
                                autoPlay
                                muted
                                playsInline
                                className='preview-video'
                                data-qa={camera ? "vid-preview-enabled" : "vid-preview-disabled"}
                            />

                            {!camera ?
                                <div className="go-live-cover">
                                    <div className="go-live-box">
                                        <div
                                            className="go-live-main"
                                            style={{
                                                backgroundColor: getUserHSLcolor(
                                                    userId
                                                ),
                                            }}
                                        >
                                            {classInfo.uname && classInfo.uname.slice(0, 2).toUpperCase()}
                                        </div>
                                    </div>
                                </div>
                                : null}

                            <div className="go-live-bottom">

                                <div className="button-container lean"
                                    data-qa="btn-audio-preview"
                                    onClick={() => {
                                        if (!audioPermissionDenied) {
                                            dispatch(audioActions.setMicStatus(!mic))
                                        }
                                    }}>
                                    <Tooltip styleClasses="flex-center"
                                        content={audioPermissionDenied ? t("enableAudioPermissions") : mic ? t("switchOffMic") : t("switchOnMic")}>
                                        <img className={`${audioPermissionDenied ? 'disable-btn cursor-normal' : 'enable-btn'}`}
                                            src={mic ? MicGreen : MicRed}
                                            alt=""
                                        />
                                    </Tooltip>
                                </div>
                                {isLaptop ?
                                    <div>
                                        <div className="button-container">
                                            {
                                                recording &&
                                                <span className="recording-indicator-container">
                                                    <span className="recording-indicator-anim" />
                                                </span>
                                            }
                                            <span className={`button-container-sub ${videoPermissionDenied ? 'cursor-normal' : ''}`}
                                                onClick={() => {
                                                    if (!videoPermissionDenied)
                                                        handlePreviewVideo(camera)
                                                }}
                                                data-qa="btn-video-preview"
                                            >
                                                <Tooltip styleClasses="flex-center"
                                                    content={videoPermissionDenied ? t("enableVideoPermissions") : camera ? t("switchOffCamera") : t("switchOnCamera")}>
                                                    <img
                                                        className={`icon ${videoPermissionDenied ? 'disable-btn cursor-normal' : 'enable-btn'}`}
                                                        src={camera ? VideoGreen : VideoRed}
                                                        alt=""
                                                        style={{ "marginLeft": "5px", "height": "24px" }}
                                                    />
                                                </Tooltip>
                                            </span>
                                            <img style={{ opacity: "0.8" }} src={Separator} alt="" />
                                            <span className={`button-container-sub ${!videoPermissionDenied && camera ? '' : 'cursor-normal'}`}
                                                onClick={(e) => {
                                                    if (!videoPermissionDenied && camera) {
                                                        e.stopPropagation();
                                                        setShowQualityOption(!showQualityOption);
                                                        dispatch(updateIsPopupOpened(true))
                                                    }
                                                }}
                                            >
                                                <img alt='quality'
                                                    src={
                                                        showQualityOption
                                                            ? DownwardArrow
                                                            : UpwardArrow
                                                    }
                                                    onClick={(e) => {
                                                        if (!videoPermissionDenied && camera) {
                                                            e.stopPropagation();
                                                            setShowQualityOption(!showQualityOption);
                                                            dispatch(updateIsPopupOpened(true))
                                                        }
                                                    }}
                                                    className={`${!videoPermissionDenied && camera ? 'enable-btn' : 'disable-btn cursor-normal'}`}
                                                />
                                            </span>
                                        </div>
                                        {showQualityOption ?
                                            <div className="video-options-panel" style={{
                                                bottom: '42vh'
                                            }}>
                                                <span style={{ textAlign: 'center', display: 'block' }}>Quality</span>
                                                <div style={{ display: 'block' }}>
                                                    {Object.values(videoOptions).map((obj, index) => {
                                                        if (companyId === COMPANY.TEACHMINT && obj.quality === QUALITY.HD) {
                                                            return null; //TODO: customization of UI components from backend
                                                        }
                                                        return (
                                                            <div
                                                                key={index}
                                                                className={`video-option ${obj.quality === currVideoQuality.quality
                                                                    ? "selected"
                                                                    : ""
                                                                    }`}
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    setVideoQuality(obj)
                                                                    dispatch(updateIsPopupOpened(true))
                                                                }}
                                                            >
                                                                {t(obj.quality)}
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </div>
                                            : null}
                                    </div>
                                    :
                                    <div className="button-container lean">
                                        {
                                            recording &&
                                            <span className="recording-indicator-container mobile">
                                                <span className="recording-indicator-anim" />
                                            </span>
                                        }
                                        <img
                                            className={`icon ${videoPermissionDenied ? 'disable-btn cursor-normal' : 'enable-btn'}`}
                                            src={camera ? VideoGreen : VideoRed}
                                            alt=""
                                            onClick={() => {
                                                if (!videoPermissionDenied)
                                                    handlePreviewVideo(camera)
                                            }}
                                            style={{ "marginLeft": "5px" }}
                                        />
                                    </div>
                                }
                                <div className="button-container lean"
                                    onClick={() => {
                                        if (companyId !== COMPANY.PLANETSPARK) {
                                            dispatch(videoroomActions.updateSetting({
                                                setting: "recording",
                                                value: !recording
                                            }))
                                        }
                                    }}>
                                    <Tooltip styleClasses="flex-center" content={recording ? t("goLiveStopRecordingButtonText") : t("goLiveStartRecordingButtonText")}>
                                        <img className={`${companyId === COMPANY.PLANETSPARK ? 'disable-btn' : 'pointer enable-btn'}`}
                                            src={recording ? RecordingOn : RecordingOff}
                                            alt="record"
                                        />
                                    </Tooltip>
                                </div>
                            </div>
                        </div>
                        {isMobile ?
                            <div className='go-live-btn'>
                                <div className='center'>
                                    <button onClick={onClickGoLive}>
                                        {t("goLiveText")}
                                    </button>
                                </div>
                            </div>
                            : null}
                    </div>

                    {!isMobile ?
                        <div className='go-live-sub'>
                            <div className='go-live-classname center'>
                                {classInfo.name}
                            </div>

                            <div className='go-live-recording center'>
                                {recording ? t("goLiveClassRecordingOnText") : t("goLiveClassRecordingOffText")}
                            </div>

                            <div className='go-live-btn'>
                                <div className='center'>
                                    <button onClick={onClickGoLive}>
                                        {t("goLiveText")}
                                    </button>
                                </div>
                            </div>
                        </div>
                        : null}
                </div>
                <div className="go-live-controls-container">
                    {currAudioDevice && currAudioDevice.label ?
                        <div className='device-sub'>
                            <div className='device-type'>{t("microphoneText")}</div>
                            <div className='video-device-selected'
                                onClick={(e) => {
                                    e.stopPropagation()
                                    setShowAudioDevice(!showAudioDevice)
                                    dispatch(updateIsPopupOpened(true))
                                }}
                            >
                                <img alt='preview_audio' src={MicGrey} />
                                <div className='device-selected-text'>
                                    {currAudioDevice ? currAudioDevice.label : ''}
                                </div>
                                <img alt='audio_device' src={showAudioDevice ? UpGrey : DownGrey} />
                            </div>

                            {showAudioDevice ?
                                <div className='device-container'>
                                    {audioDevices.map((device, index) => {
                                        return (
                                            <div className='video-device' key={index} onClick={() => {
                                                setDevicePreviewAudio(device)
                                            }}>
                                                {device.label}
                                            </div>
                                        )
                                    })}
                                </div>
                                : null}
                        </div>
                        : null}

                    {currVideoDevice && currVideoDevice.label ?
                        <div className='device-sub'>
                            <div className='device-type'>{t("cameraText")}</div>
                            <div className='video-device-selected'
                                onClick={(e) => {
                                    e.stopPropagation()
                                    setShowVideoDevice(!showVideoDevice)
                                    dispatch(updateIsPopupOpened(true))
                                }}
                            >
                                <img alt='preview' src={CameraGrey} />
                                <div className='device-selected-text'>
                                    {currVideoDevice ? currVideoDevice.label : ''}
                                </div>
                                <img alt='video-device' src={showVideoDevice ? UpGrey : DownGrey} />
                            </div>

                            {showVideoDevice ?
                                <div className='device-container'>
                                    {videoDevices.map((device, index) => {
                                        return (
                                            <div className='video-device' key={index} onClick={() => {
                                                setDevicePreviewVideo(device);
                                            }}> {device.label} </div>
                                        )
                                    })}
                                </div>
                                : null}
                        </div>
                        : null}
                </div>
            </div >
        </>
    );
}